export const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjM0LCJyb2xlIjoiYmFzaWMiLCJpYXQiOjE2OTI0NjI4NjYsImV4cCI6MTY5NTA1NDg2Nn0.OoZBlAz-WBQ10rtLt90DWIRUoYeufkANhgS5iyU7hDQ";

export const token1 = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjI1LCJyb2xlIjoiYmFzaWMiLCJpYXQiOjE2OTI1MTc1NDAsImV4cCI6MTY5NTEwOTU0MH0.mok3MFiXK18NJ1AhWhnsSbsyuMyUzFigQikSGu_gXx0"

// export const token2 = ""

// export const baseURL = "https://192.168.1.242:3000/api/v1";

export const baseURL = "https://backend.vmludo.in";
// export const baseURL = "http://localhost:5010";
// export const baseURL = "https://1t4f35fh-3000.inc1.devtunnels.ms/api/v1";